import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import FullScreenSection from "../components/full-screen-section"
import Seo from "../components/seo"

import styled from 'styled-components'

import * as THREE from "three";

import { Suspense , useRef, useState, useMemo, useEffect } from 'react';

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { HemisphereLight } from "three/src/lights/HemisphereLight";
// import { HemisphereLightProbe } from "three/scr/lights/HemisphereLightProbe";
import { AmbientLight } from "three/src/lights/AmbientLight";

// const TWEEN = require('@tweenjs/tween.js');

import * as TWEEN from "@tweenjs/tween.js";

const ThreePage = () => {

  const data = useStaticQuery(
    graphql`
      query {
        bg1:

        file(relativePath: { eq: "pointcloud.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg2:
        file(relativePath: { eq: "obj.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        thumb1:
        file(relativePath: { eq: "p02.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }        

      }
    `
  )

  const bg1 = data.bg1.childImageSharp.fluid
  const bg2 = data.bg2.childImageSharp.fluid
  const thumb1 = data.thumb1.childImageSharp.fluid

const Vis = () => {

  const { useRef, useEffect, useState } = React
  const mount = useRef(null)
  const [isAnimating, setAnimating] = useState(true)
  // const controls = useRef(null)
  
  useEffect(() => {

    let width = window.innerWidth;
    let height = window.innerHeight;
    let frameId;

    let arrow;

    const scene = new THREE.Scene()

    scene.background = new THREE.Color().setHSL( 0.6, 0, 1 );
    scene.fog = new THREE.Fog( scene.background, 1, 5000 );

    let camera = new THREE.PerspectiveCamera(75, width / height, 0.001, 100)
    
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    renderer.toneMapping = THREE.LinearToneMapping;
    renderer.toneMappingExposure = 1.25;

    renderer.outputEncoding = THREE.sRGBEncoding;

    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();  


    new RGBELoader().load( '/3DCollective_HDRi_034_1751_+32_1K.hdr', (texture) => {
      // texture.mapping = THREE.EquirectangularRefractionMapping;
      texture.mapping = THREE.EquirectangularReflectionMapping;
      scene.background = texture;
      scene.environment  = texture;      
    });


    // const light = new THREE.PointLight(0xFFFFFF);
    // const light = new THREE.AmbientLight(0xFFFFFF, 0.75);
    // light.position.x = 0;
    // light.position.y = 4;
    // light.position.z = 0;

    // const hemiLight = new THREE.HemisphereLight( 'white', 'darkslategrey', 1.8 );
    // hemiLight.color.setHSL( 0.6, 1, 0.6 );
    // hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );
    // hemiLight.position.set( 0, 0.25, 0 );
    // hemiLight.scale.set( 0.01, 0.01, 0.01 );
    // const hemiLightHelper = new THREE.HemisphereLightHelper( hemiLight, 10 )

    // scene.add( hemiLight )
    // scene.add( hemiLightHelper );


    // scene.add(light);

    // renderer.shadowMap.enabled = true;
    // renderer.shadowMap.type = THREE.PCFShadowMap;

    // light.castShadow = true;
    // light.shadow = new THREE.LightShadow( new THREE.PerspectiveCamera( 50, 1, 10, 2500 ) );
    // light.shadow.bias = 0.0001;
    // light.shadow.mapSize.width = 2048;
    // light.shadow.mapSize.height = 1024;    

    
    const controls = new OrbitControls( camera, renderer.domElement );

    const loader = new GLTFLoader();

    loader.load(
      // resource URL
      '/3dcdll_texturas_2048.glb',
      // called when the resource is loaded
      function ( gltf ) {

        console.dir(gltf);

        gltf.scene.scale.set(0.01, 0.01, 0.01);
        gltf.scene.castShadow = true;

        scene.add( gltf.scene );

        // gltf.animations; // Array<THREE.AnimationClip>
        // gltf.scene; // THREE.Group
        // gltf.scenes; // Array<THREE.Group>
        // gltf.cameras; // Array<THREE.Camera>
        // gltf.asset; // Object

      },
      // called while loading is progressing
      function ( xhr ) {

        // console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

      },
      // called when loading has errors
      function ( error ) {

        console.log( 'An error happened' );

      }
    );

    loader.load('/camaras.glb', function (gltf) {

      gltf.scene.name = 'camaras';

      scene.add( gltf.scene );

      // gltf.scene.children.forEach(children => {
      //   // scene.add(children);
      // })
      // camera = gltf.cameras[1];
      // gltf.cameras.forEach(camera => {
      //   console.dir(camera);
      //   scene.add(camera);
      // })

    const p02texture = new THREE.TextureLoader().load( '/p02.jpg' );
    const p02material = new THREE.MeshPhongMaterial( { map: p02texture, side: THREE.DoubleSide } );
    scene.getObjectByName( "pPlane2" ).material = p02material;
    // scene.getObjectByName( "pPlane2" ).rotateZ(Math.PI * 2);
    // scene.getObjectByName( "pPlane2" ).rotateX(Math.PI * 2 * 180);

    });

    // const geometry = new THREE.PlaneGeometry( 1, 2 );
    // const material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );    
    // const plane = new THREE.Mesh( geometry, material );
    // plane.rotation.y = Math.PI / 2;
    // plane.position.x = 20;
    // plane.position.z = -10;
    // plane.position.y = 1;
    // plane.name = 'walk1';
    // scene.add( plane );    

    camera.position.z = 1

    controls.update();
    
    // scene.add(cube)
    // renderer.setClearColor('#FFFFFF')
    renderer.setSize(width, height)



    // scene.getObjectByName( "pPlane2" ).material = p02material;

    

    const raycast = (e) => {

      //1. sets the mouse position with a coordinate system where the center
      //   of the screen is the origin
      pointer.x = ( e.clientX / window.innerWidth ) * 2 - 1;
      pointer.y = - ( e.clientY / window.innerHeight ) * 2 + 1;

      //2. set the picking ray from the camera position and mouse coordinates
      raycaster.setFromCamera( pointer, camera );    

      //3. compute intersections
      var intersects = raycaster.intersectObjects( scene.children, true );

      for ( var i = 0; i < intersects.length; i++ ) {
          if (intersects[ i ].object.name == 'pPlane2') {
            // controls.disable();
            // console.log('click');

            // var persp3 = scene.getObjectByName( "persp3_Orientation" );
            let persp2 = scene.getObjectByName( "persp2" );

            // var persp1 = scene.getObjectByName( "persp1_Orientation" );

            // intersects[ i ].object.material.color.set( 0xff0000 );
            cameraToMarker(persp2, scene.getObjectByName( "pPlane2" ));
          } else if (intersects[ i ].object.name == 'calles') {
            console.dir(intersects[i]);
            cameraToPoint(intersects[i].point);
          }


          /*
              An intersection has the following properties :
                  - object : intersected object (THREE.Mesh)
                  - distance : distance from camera to intersection (number)
                  - face : intersected face (THREE.Face3)
                  - faceIndex : intersected face index (number)
                  - point : intersection point (THREE.Vector3)
                  - uv : intersection point in the object's UV coordinates (THREE.Vector2)
          */
      }
    }

    const cameraToMarker = (marker, target) => {

      const currentCamPosition = {x: camera.position.x, y: camera.position.y, z: camera.position.z};      
      const markerPosition = new THREE.Vector3(marker.position.x, marker.position.y, marker.position.z);
      // const newCameraTarget = getNewPointOnVector(currentCamPosition, storedMarkerPosition);
      // const markerPosition = new THREE.Vector3(...Object.values(newCameraTarget));
      const startRotation = new THREE.Euler().copy(camera.rotation);
      // const startQuaternion = camera.quaternion.clone();
      // const endQuaternion = marker.quaternion.clone();
      // camera.lookAt(storedMarkerPosition);
      // const endRotation = new THREE.Euler().copy(camera.rotation);
      // camera.rotation.copy(startRotation);

      
      const endLookAtVector = new THREE.Vector3(target.position.x, target.position.y, target.position.z);

      const newCameraPos = {
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
        lookAt: new THREE.Vector3(0,0, -1).applyQuaternion(camera.quaternion)
      }

      // new TWEEN.Tween(camera.rotation)
        // .to(
          // {
            // x: endRotation.x,
            // y: endRotation.y,
            // z: endRotation.z,
          // }, 500)
        // .easing(TWEEN.Easing.Quadratic.InOut)
        // .onComplete(() => {
          new TWEEN.Tween(newCameraPos)
            .to({
              x: marker.position.x,
              y: marker.position.y,
              z: marker.position.z,
              lookAt: new THREE.Vector3(target.position.x, target.position.y, target.position.z)
            }, 3000)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onUpdate(() => {
              // camera.position.x = newCameraPos.x;
              // camera.position.y = newCameraPos.y;
              // camera.position.z = newCameraPos.z;
              camera.lookAt(newCameraPos.lookAt);
              camera.position.set(newCameraPos.x, newCameraPos.y, newCameraPos.z);
            })
            .onComplete(() => {
              target.material.transparent = true;
              target.material.opacity = 0.5;
              

              // let radius = Math.hypot(...Object.values(markerPosition));
              // let phi = Math.acos(markerPosition.y / radius);
              // let theta = Math.atan2(markerPosition.z, markerPosition.x);
              // let lon = THREE.Math.radToDeg(theta);
              // let lat = 90 - THREE.Math.radToDeg(phi);
            })
            .start();
        // })
        // .start();
    }

    const cameraToPoint = (marker) => {
      const currentCamPosition = {x: camera.position.x, y: camera.position.y, z: camera.position.z};      
      const markerPosition = new THREE.Vector3(marker.x, marker.y, marker.z);
      const startRotation = new THREE.Euler().copy(camera.rotation); 

      const newCameraPos = {
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
        lookAt: new THREE.Vector3(0,0, -1).applyQuaternion(camera.quaternion)
      }

      new TWEEN.Tween(newCameraPos)
        .to({
          x: marker.x,
          y: marker.y+0.025,
          z: marker.z,
          // lookAt: new THREE.Vector3(target.position.x, target.position.y, target.position.z)
        }, 3000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(() => {
          // camera.lookAt(newCameraPos.lookAt);
          camera.position.set(newCameraPos.x, newCameraPos.y, newCameraPos.z);
        })
        .onComplete(() => {

        })
        .start();

    }

    const getNewPointOnVector = (p1, p2) => {
      let distAway = 200;
      let vector = {x: p2.x - p1.x, y: p2.y - p1.y, z:p2.z - p1.z};
      let vl = Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2) + Math.pow(vector.z, 2));
      let vectorLength = {x: vector.x/vl, y: vector.y/vl, z: vector.z/vl};
      let v = {x: distAway * vectorLength.x, y: distAway * vectorLength.y, z: distAway * vectorLength.z};
      return {x: p2.x + v.x, y: p2.y + v.y, z: p2.z + v.z};
    }  

    const renderScene = () => {
      renderer.render(scene, camera)
    }

    const flyTo = () => {

    }

    const handleResize = () => {
      width = mount.current.clientWidth
      height = mount.current.clientHeight
      renderer.setSize(width, height)
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderScene()
    }
    
    const animate = (time) => {
      // cube.rotation.x += 0.01
      // cube.rotation.y += 0.01

      renderScene()
      frameId = window.requestAnimationFrame(animate);
      TWEEN.update(time)
      
    }

    const start = () => {
      window.addEventListener( 'pointerdown', raycast, false );
      if (!frameId) {
        frameId = requestAnimationFrame(animate)
      }
    }

    const stop = () => {
      cancelAnimationFrame(frameId)
      frameId = null
    }

    mount.current.appendChild(renderer.domElement)
    window.addEventListener('resize', handleResize)
    start()

    // controls.current = { start, stop }
    
    return () => {
      stop()
      window.removeEventListener('resize', handleResize)
      mount.current.removeChild(renderer.domElement)

      // scene.remove(cube)
      // geometry.dispose()
      // material.dispose()
    }
  }, [])

  useEffect(() => {
    if (isAnimating) {
      // controls.current.start()
    } else {
      // controls.current.stop()
    }
  }, [isAnimating])
  
  return <div className="vis h-100" ref={mount} onClick={() => setAnimating(!isAnimating)} />
}

  return (
    <Layout>
      <Seo title="Home" />

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid g-0 fullheight-ribbon d-flex flex-column'}
          fluid={bg1}
          backgroundColor={`#040e18`}
        >

            <div className={`row flex-1`}>
              <div className={`col flex-1`}>


              <Vis />

              <div className={`col-1 three-nav p-2`}>

                <div className={`three-nav-thumb-div`}>
                  <StaticImage className={`three-nav-thumb`} src="../images/p02.jpg" alt={`p02`} />
                  <p className={`text-white fs-6`}>Vista Histórica 1</p>
                  <StaticImage className={`three-nav-thumb`} src="../images/p02.jpg" alt={`p02`} />
                  <p className={`text-white fs-6`}>Vista Histórica 1</p>
                  <StaticImage className={`three-nav-thumb`} src="../images/p02.jpg" alt={`p02`} />
                  <p className={`text-white fs-6`}>Vista Histórica 1</p>
                </div>

              </div>

              </div>         
            </div>
            <div className={`row ribbon no-gutters`}>

            	<div className={`col-3 ribbon-blue`}></div>
            	<div className={`col-2 ribbon-red`}></div>
            	<div className={`col-4 ribbon-navy`}></div>
            	<div className={`col-3 ribbon-gray`}></div>

            </div>

        </BackgroundImage>

      </FullScreenSection>   

    </Layout>
  )

}

const StyledBackgroundSection = styled(ThreePage)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default ThreePage
